.email {
  color: #003049;
  font-size: 35px;
  margin-top: 5px;
  text-align: center;
}

.content {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.profile-favorite-btn {
  background-color: #ae2a09;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 13px;
  font-weight: 800;
  padding: 0.3rem 0.2rem;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
}
