.recipe-photo {
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  width: 90%;
}

.recipecategor {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #003049;
  margin-top: 2px;
  text-align: center;
}

.share-and-favorite-btn {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.recipe-title {
  color: #ae2b09;
  text-align: center;
  padding: 1rem;
}

.instructions-imporgress {
  background-color: #f6f4d2cb;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #003049;
  display: flex;
  flex-direction: column;
  list-style-position: inside;
  list-style-type: disc;
  margin: 10px;
  margin-left: 3px;
  padding: 3px;
  text-align: left;
}

.finish-btn {
  text-align: center;
}
