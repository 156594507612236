.recipe-photo {
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  width: 90%;
}


.share-and-favorite-btn {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.recipe-title {
  color: #ae2b09;
  text-align: center;
  padding: 1rem;
}

.recipe-categorie {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #003049;
  margin-top: 2px;
  text-align: center;
}

.ingredients {
  background-color: #ece7cf;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  list-style-position: inside;
  list-style-type: disc;
  margin: 10px;
  padding: 2rem 6rem;
  text-align: left;
  color: #003049;
}

.instructions {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #333;
  font-family: Arial , sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  padding: 2rem;
  color: #003049;
  text-align: justify;
}

.video {
  width: 100%;
}

.startRecipe {
  background-color: #ae2b09;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  font-size: large;
  font-weight: 600;
  height: 40px;
  margin-bottom: 10px;
  margin-top: -15px;
  padding: 7px 0;
  width: 100%;
}


.btn-init {
  display: flex;
  justify-content: center;
}

.Finish-btn {
  background-color: #ae2b09;
  width: 100%;
}
