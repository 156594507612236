.recipe-card {
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 5%;
  justify-content: space-between;
  margin: 2px;
  padding: 5px;
}

.recipe-card p {
  color: #ae2b09;
  font-size: larger;
  margin-top: 1px;
}

a {
  text-decoration: none;
}

.recipe-img {
  border-radius: 10px;
  height: 90%;
  width: 90%;
}

.card-name {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
  margin-top: -10px;
  text-align: center;
  text-decoration: none;
}


.meals {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
  text-align: center;
}

.drinks {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px 0;
}

/* .recommendation {
  background-color: #ece7cf;
  display: flex;
  flex-flow: column wrap;
  font-size: 13px;
  gap: 1rem;
  height: 200px;
  justify-content: center;
  margin: 0 1rem;
  overflow: auto;
  scroll-snap-type: x mandatory;
  width: 100%;
} */

.recommendation {
  display: flex;
  margin-bottom: 30px;
  margin-top: 10px;
  overflow-x: auto;
  padding-bottom: 30px;
}

.recommendation::-webkit-scrollbar {
  height: 8px;
}

.recommendation::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.recommendation::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.recommendation::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}


.recipe-card img {
  border-radius: 5px;
  width: 165px;
}

.startRecipe {
  background-color: #f77f00;
  border-radius: 5px;
  bottom: 0;
  color: #ece7cf;
  cursor: pointer;
  font-weight: 600;
  height: 40px;
  padding: 7px 0;
  position: fixed;
  width: 100%;
}
