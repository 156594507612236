.header {
  align-items: center;
  box-shadow: 0 0 10px 0 #ece7cf;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.header:before {
  background-image: url('https://blog.wokgrill.com.br/wp-content/uploads/2022/06/post_thumbnail-573a89b3003093114762a925aa15ac90-780x450.jpeg');
  content: '';
  filter: blur(5px);
  height: 100%;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.header .background-image {
  background-repeat: repeat-y;
  background-size: cover;
  height: 100%;
}


.top-itens {
  margin: 5px;
}

/* .meals {
  background-color: rgba(255, 255, 255, 0.842);
} */

.minilogo {
  width: 70px;
}

.header-icons {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page-title {
  color: #ae2b09;
  font-size: 30px;
}

.top-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

input {
  border: none;
  border-radius: 5px;
  gap: 1rem;
  margin: 0 10px;
  padding: 10px;
  text-align: center;
}

input::placeholder {
  font-weight: bold;
  opacity: 0.5;
  color: #003049;
}

.search-input {
  background-color: #ece7cf;
  border: #003049 solid 2px;
  border-radius: 5px;
  color: #003049;
  padding: 7px 10px;
  text-align: start;
  width: 100%;
}

button {
  background-color: transparent;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}

.search-btn {
  background-color: #ae2b09;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  font-size: large;
  font-weight: 600;
  height: 40px;
  margin: 0 10px;
  margin-bottom: 10px;
  margin-top: -15px;
  padding: 7px 0;
  padding-bottom: 5px;
  width: 100%;
  letter-spacing: 2px;
}

.search-btn:hover {
  background-color: #003049;
  color: #ece7cf;
}

form {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}

.rabio-opts {
  align-items: center;
  color: #003049;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  text-align: center;
}

.label-opt {
  margin-left: -10px;
}

.radio-input {
  appearance: none;
  outline: none;
}

.radio-input:before {
  background-color: transparent;
  border: 1px solid #0000003f;
  border-radius: 50%;
  content: '';
  display: inline-block;
  height: 16px;
  margin-right: 8px;
  transition: background-color 0.3s ease;
  vertical-align: middle;
  width: 16px;
}

.radio-input:checked:before {
  background-color: #a44a3f;
}

.ingredients-check .label-opt {
  margin-left: -10px;
}

.ingredients-check .radio-input {
  appearance: none;
  outline: none;
}

.ingredients-check .radio-input:before {
  background-color: transparent;
  border: 1px solid #0000003f;
  border-radius: 50%;
  content: '';
  display: inline-block;
  height: 16px;
  margin-right: 8px;
  transition: background-color 0.3s ease;
  vertical-align: middle;
  width: 16px;
}

.ingredients-check .radio-input:checked:before {
  background-color: #a44a3f;
}
