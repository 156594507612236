.finish-recipe-btn {
  background-color: #ae2b09;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  font-size: large;
  font-weight: 600;
  height: 40px;
  margin-bottom: 10px;
  margin-top: -15px;
  padding: 7px 0;
  padding-bottom: 5px;
  width: 100%;
}

.finish-recipe-btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.finish-recipe-btn:hover {
  background-color: #003049;
  color: #ece7cf;
}
