@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap') ;

* {
  box-sizing: border-box;
  font-family: Raleway , sans-serif;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4 {
  color: #003049;
  font-weight: 800;
}

body {
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}

/* Estilizando a barra de rolagem no Chrome, Safari e Edge */
body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent;
}

@media screen and ( min-width : 1200px ) {

  body {
    margin: 0 auto;
    width: 27%;
  }
}
