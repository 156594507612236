footer {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.432);
  bottom: 0;
  display: flex;
  height: 10;
  justify-content: space-around;
  position: fixed;
  width: 100%;
}

.footer-section {
  bottom: 65px;
  position: absolute;
}

.meals-icon {
  background-color: transparent;
  size: 60px;
}
