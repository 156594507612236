.backbtn {
  align-items: center;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-left: 10px;
  margin-top: 5px;
  position: absolute;
}

.backbtn-img {
  width: 55px;
}
