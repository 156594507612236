.page {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100vh;
  justify-content: center;
  position: relative;
}


.page:before {
  background-image: url('https://images.unsplash.com/photo-1606787366850-de6330128bfc?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Zm9vZCUyMHdhbGxwYXBlcnxlbnwwfHwwfHx8MA%3D%3D&w=1000&q=80');
  background-size: cover;
  content: '';
  filter: blur(5px);
  height: 100%;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}


.form {
  align-items: center;
  background-color: #f6f4d2cb;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 2rem;
}

.input {
  background-color: white;
  border: #f19c79 solid 1px;
  border-radius: 5px;
  padding: 7px 10px;
  width: 100%;
  text-align: left;
  color: #003049;
}

.input::placeholder {
  font-weight: bold;
  opacity: 0.5;
  color: #003049;
  text-align: left;
}

.btn {
  background-color: #a44a3f;
  border-radius: 5px;
  color: #cbdfbd;
  cursor: pointer;
  padding: 7px 0;
  width: 100%;
}

.btn:disabled {
  opacity: 50%;
}

.btn:hover {
  background-color: #003049;
  color: #cbdfbd;
}

.logo-login {
  width: 150px;
}
