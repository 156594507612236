.done-recipe-img {
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  height: 90%;
  text-align: center;
  width: 90%;
}

.done-recipe-card {
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 5%;
  justify-content: space-between;
  margin: 2px;
  padding: 5px;
  text-align: center;
  width: 45%;
}

